export interface Address {
    country: Country | null;
    county: County | null;
    city: City | null;
    street_type: StreetType | null;
    street: string | null;
    street_number: string | null;
    building: string | null;
    floor: string | null;
    entrance: string | null;
    apartment: string | null;
    postal_code: string | null;
}

export interface StreetType {
    id: number | null;
    code: string | null;
    name: string | null;
}

export interface AddressFormValidation {
    street_type: boolean;
    street: boolean;
    street_number: boolean;
    building: boolean;
    entrance: boolean;
    floor: boolean;
    apartment: boolean;
    country: boolean;
    county: boolean;
    city: boolean;
    postal_code: boolean;
    [key: string]: boolean | boolean[]; // Add index signature
}

export const createDefaultAddress = (): Address => {
    return {
        country: null,
        county: null,
        city: null,
        street_type: {
            id: 0,
            code: "strada",
            name: "Strada",
        },
        street: null,
        street_number: null,
        building: null,
        floor: null,
        entrance: null,
        apartment: null,
        postal_code: null,
    }
}

export const createDefaultAddressFormValidation = (): AddressFormValidation => {
    return {
        street_type: true,
        street: false,
        street_number: false,
        building: false,
        entrance: false,
        floor: false,
        apartment: false,
        country: false,
        county: false,
        city: false,
        postal_code: false,
    }
}

export interface Continent {
    id: number | null;
    name: string | null;
    code: string | null;
}

export interface ApiContinent {
    id: number | null;
    value: string | null;
    code: string | null;
}

export interface Country {
    id: number | null;
    name: string | null;
    short_code: string | null;
    continent_code: string | null;
}

export interface County {
    id: number | null;
    code: string | null;
    name: string | null;
}

export interface City {
    id: number | null;
    name: string | null;
}
